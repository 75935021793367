import React from 'react'

const className = 'font-sansSerif text-p1 antialiased md:text-p1md'
export const P1ClassName = className

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  as?: 'div' | 'span' | 'a'
  href?: string
  small?: boolean
}

const P1: React.FC<Props> = ({ small, children, className, ...other }) => {
  return (
    <p
      className={
        P1ClassName +
        (className ? ` ${className}` : '') +
        (small ? ' !text-p2 md:!text-p3md' : '')
      }
      {...other}
    >
      {children}
    </p>
  )
}

export default P1
